.menu-container {
  margin-left: 20px;
  margin-right: 20px;
}

.profile {
  display: flex;
  justify-content: space-between;
  color: #234493;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 20px;
}

.in-toolbar {
  --background: fff;
}

.menu-user-data {
  img {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    border: 2px solid #234493;
    padding: 2px;
    margin-right: 10px;
  }
  div{
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #234493;
    font-size: 14px;
    line-height: 18px;
  }
}

.menu-item div {
  float: left;
}

.close-menu {
  text-align: right;
}

.menu-item {
  margin-top: 20px;
  width: 100%;
  display: table;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 20px;
  .icon {
    margin-right: 10px;
  }
  .m-link{
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #262626;
    font-size: 14px;
    line-height: 20px;
  }
}
