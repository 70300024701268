
.mt-20{
  margin-top: 20px;
}

.mt-28{
  margin-top: 28px;
}

.mt-30{
  margin-top: 30px;
}

.mt-60{
  margin-top: 60px;
}
.mt-50{
  margin-top: 50px;
}
.mt-40{
  margin-top: 40px;
}
