@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap");
ion-col {
  padding-left: 34px;
  padding-right: 34px;
}

.main-logo {
  margin-top: 45px;
}
.main-logo img {
  width: 168px;
  height: 52px;
}

.login-text {
  margin-bottom: 16px;
  color: #4a4b4d;
  font-size: 20px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

.login-input {
  background: #f2f2f2;
  height: 56px;
  border-radius: 50px;
  -–border-radius: 50px;
}

.login-item {
  background: transparent;
  --background: transparent;
}

.login-btn {
  background: #234493;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.forget-link {
  margin-top: 11px;
  color: #234493;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.or {
  margin-top: 13px;
  color: #7c7d7e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  height: 1px;
  border-top: 1px solid #7c7d7e;
  text-align: center;
  position: relative;
}
.or span {
  position: relative;
  top: -0.7em;
  background: white;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.login-with {
  margin-top: 10px;
  color: #7c7d7e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.facebook-btn {
  height: 56px;
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  background: #4267b2;
  cursor: pointer;
}
.facebook-btn span {
  margin: 0 auto;
  background: url("../imgs/facebook-icon.png") no-repeat 0 50%;
  padding-left: 30px;
}

.google-btn {
  background: #e52729;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}
.google-btn span {
  margin: 0 auto;
  background: url("../imgs/google-plus.png") no-repeat 0 50%;
  padding-left: 30px;
}

.guest-btn {
  background: #234493;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}
.guest-btn span {
  margin: 0 auto;
  background: url("../imgs/guest.png") no-repeat 0 40%;
  padding-left: 30px;
  height: 24px;
  display: table;
}

.reg-div {
  color: #7c7d7e;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-top: 21px;
  margin-bottom: 40px;
  cursor: pointer;
}
.reg-div span {
  font-weight: 800;
  color: #234493;
}

.auth-title {
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #4a4b4d;
  margin-top: 45px;
  font-size: 20px;
}

.auth-hint {
  line-height: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #4a4b4d;
  margin-top: 45px;
  font-size: 14px;
  margin-top: 21px;
}

.otp-input {
  background: #f2f2f2;
  height: 56px;
  width: 56px;
  border-radius: 20px;
}

.sc-ion-input-ios-h {
  --padding-top: 0;
  --padding-end: 10;
  --padding-bottom: 10;
  --padding-start: 0;
}

.login-page {
  max-width: 500px;
  margin: 0 auto;
}

.btn-disabled {
  color: #4A4B4D;
  background: #7C7D7E;
}

.mt-20 {
  margin-top: 20px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.menu-container {
  margin-left: 20px;
  margin-right: 20px;
}

.profile {
  display: flex;
  justify-content: space-between;
  color: #234493;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 20px;
}

.in-toolbar {
  --background: fff;
}

.menu-user-data img {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  border: 2px solid #234493;
  padding: 2px;
  margin-right: 10px;
}
.menu-user-data div {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #234493;
  font-size: 14px;
  line-height: 18px;
}

.menu-item div {
  float: left;
}

.close-menu {
  text-align: right;
}

.menu-item {
  margin-top: 20px;
  width: 100%;
  display: table;
  border-bottom: 1px solid #bdc3c7;
  padding-bottom: 20px;
}
.menu-item .icon {
  margin-right: 10px;
}
.menu-item .m-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #262626;
  font-size: 14px;
  line-height: 20px;
}

ion-header {
  height: 96px;
  background: #234493;
  color: #fff;
}

ion-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 30px;
}

ion-toolbar {
  --border-color: #234493;
}

.transIcon {
  padding: 7px;
  margin: 5px 0 0 5px;
  background-color: #e4e4e48d;
  border-radius: 10px;
}

p:focus,
ion-img {
  outline: none;
}

.otp-buttons-padding {
  padding-left: 41px;
  padding-right: 34px;
}

@media only screen and (min-width: 997px) {
  .otp-buttons-padding {
    padding-left: 66px;
    padding-right: 34px;
  }
}
.no-results {
  text-align: center;
  margin: 41px auto;
  display: table;
}

ion-searchbar {
  padding: 0 8px !important;
}

.eye-icon {
  color: #c7c7c7;
}

.lobster {
  font-family: "Lobster", "Quicksand", "Cairo", serif;
}

/* hide scrollbar chrome */
* ::-webkit-scrollbar {
  display: none;
}

ion-menu .content {
  background: var(--ion-color-light);
}

*:focus {
  outline: none !important;
}

.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}

.hidden {
  visibility: hidden;
}

.relative {
  position: relative;
}

.no-flex {
  flex: none !important;
}

.logo {
  display: flex;
  align-items: center;
}
.logo img {
  display: block;
  height: 35px;
}
.logo h2 {
  font-size: 20px;
  font-weight: bold;
  color: var(--ion-color-dark);
  margin: 0 6px;
}

.z-index-10 {
  z-index: 10;
}

.static {
  position: static !important;
}

.line {
  background: var(--ion-color-light);
  height: 1px;
  margin: 8px 0;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.block {
  display: block;
}

.bg-light {
  background: var(--ion-color-light);
}

.border-light {
  border: 1px solid var(--ion-color-light);
}

.menu-toolbar {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.menu-toolbar ion-avatar img {
  display: inline-block;
  max-width: 8.6rem;
  max-height: 8.6rem;
  border-radius: 4.8rem;
  border: 2px solid var(--ion-color-light);
}

.bold {
  font-weight: bold !important;
}

.inline-block {
  display: inline-block !important;
}

.display-inherit {
  display: inherit !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ng-lazyloaded,
.fade-in {
  animation: fadein 0.5s;
}

.full-width {
  width: 100% !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card-item {
  background-color: white;
  margin: 1px 0;
}

.card-item .titel {
  margin: 0px;
  color: #3343d1;
  font-size: 14px;
  font-weight: bold;
}

.card-item .description {
  margin: 5px 0;
  color: #2c2c2c;
  font-size: 12px;
}

.card-item img {
  display: flex;
  width: fit-content;
  height: initial;
  border-radius: 15px;
  max-height: 90px;
  margin-bottom: 5px;
}

.card-item h4 {
  margin: 5px 0;
  color: #3a3a3a;
}

.card-item p {
  margin: 5px 0;
  color: #794684;
}

.autocomplete {
  padding: 0;
  border: 1px solid var(--ion-color-light-shade);
  margin: 0 1px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

star-rating.space-around div {
  display: flex;
  justify-content: space-around;
}
star-rating .star {
  opacity: 0.3;
}
star-rating .star.on {
  opacity: 1;
}

ion-grid[fixed] {
  min-height: 100%;
}

.item-price-h3 {
  margin: 0 !important;
}

.label-quantity {
  font-size: 18px;
}

.circle:not(.loader) {
  border-radius: 16%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  border: 3px solid #ddd;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.circle:not(.loader) .circle-inner {
  padding: px;
  flex: 1;
  min-width: 0;
}
.circle:not(.loader) .circle-inner p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.circle:not(.loader) p {
  color: var(--ion-color-light);
  font-size: 12px;
}

.margin-6 {
  margin: 6px 0 !important;
}

.margin-6-horizontal {
  margin: 0 6px !important;
}

.primary-bg {
  background: var(--ion-color-primary);
}

.dark-bg {
  background: var(--ion-color-dark);
}

.light-bg {
  background-color: var(--ion-color-light);
}

.divider {
  height: 1px;
  background: var(--ion-color-light-shade);
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: stretch;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  height: 36px !important;
  white-space: normal !important;
  line-height: 18px !important;
  transition: all 0.2s ease-in-out;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 13px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-large {
  font-size: 18px !important;
}

.text-xlarge {
  font-size: 24px !important;
}

.shadow {
  box-shadow: 0px 4px 15px rgba(80, 80, 80, 0.1) !important;
}

.radius {
  border-radius: 6px;
}

.no-radius {
  border-radius: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.no-margin {
  margin: 0 !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.hide {
  display: none;
}

.label[stacked] {
  margin-bottom: 6px !important;
}

.flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

ion-toolbar ion-searchbar {
  height: 35px !important;
}

.plt-ios ion-toolbar {
  --min-height: 60px;
}
.plt-ios .select-popover {
  --width: 230px;
}

.plt-desktop .link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.plt-desktop .hover:hover {
  --background: var(--ion-color-light-tint);
  background: var(--ion-color-light-tint);
}

@media (min-width: 992px) {
  ion-tabs ion-tab-bar {
    justify-content: center;
  }
  ion-tabs ion-tab-bar ion-tab-button {
    justify-content: center;
  }

  ion-header ion-toolbar {
    --min-height: 70px;
  }
  ion-header ion-toolbar .logo img {
    height: 50px;
  }

  .circle {
    border-radius: 6px !important;
    width: 150px !important;
    height: 150px !important;
  }
}
:root lightbox gallery {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
:root lightbox gallery .g-btn-close {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.StripeElement {
  background: #f4f5f8 !important;
  padding: 16px !important;
  margin: 0 5px;
}

.swal2-container .swal2-icon .swal2-success-line-tip {
  background-color: var(--ion-color-medium-shade) !important;
}
.swal2-container .swal2-icon .swal2-success-line-long {
  background-color: var(--ion-color-medium-shade) !important;
}
.swal2-container .swal2-icon .swal2-success-ring {
  border-color: var(--ion-color-medium-tint) !important;
}
.swal2-container .swal2-actions {
  text-align: center;
}
.swal2-container .swal2-actions button.swal2-confirm {
  font-weight: bold;
}
.swal2-container .swal2-actions button.swal2-styled {
  margin-bottom: 16px;
  width: 100%;
}
.swal2-container .swal2-styled.swal2-confirm {
  background-color: var(--ion-color-primary);
}
.swal2-container .swal2-styled.swal2-cancel {
  background-color: var(--ion-color-medium);
}
.swal2-container .swal2-styled:focus {
  box-shadow: none;
}
@media (min-width: 576px) {
  .swal2-container .swal2-actions button.swal2-styled {
    margin-bottom: 0;
    width: auto;
  }
}

.alert {
  position: relative;
  padding: 0 16px;
  margin: 8px 16px;
  border: 1px solid transparent;
  border-radius: 6px;
}
.alert.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

@media (min-width: 0px) {
  .hide-xs-up {
    display: none;
  }

  .show-xs-up {
    display: block;
  }
}
@media (min-width: 576px) {
  .hide-sm-up {
    display: none;
  }

  .show-sm-up {
    display: block;
  }
}
@media (min-width: 768px) {
  .hide-md-up {
    display: none;
  }

  .show-md-up {
    display: block;
  }
}
@media (min-width: 992px) {
  .hide-lg-up {
    display: none;
  }

  .show-lg-up {
    display: block;
  }
}
@media (min-width: 1200px) {
  .hide-xl-up {
    display: none;
  }

  .show-xl-up {
    display: block;
  }
}
@media (min-width: 0px) and (max-width: 575px) {
  .hide-xs-only {
    display: none;
  }

  .show-xs-only {
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hide-sm-only {
    display: none;
  }

  .show-sm-only {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .show-md-only {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .show-lg-only {
    display: block;
  }
}
app-shop-item bar-rating .br-stars {
  margin: 0 !important;
}
app-shop-item bar-rating .br-stars .br-units {
  justify-content: center;
}
app-shop-item bar-rating .br-stars .br-unit {
  width: 12px !important;
  height: 12px !important;
}

page-item bar-rating .br-stars,
page-review-list bar-rating .br-stars {
  margin: 8px 0 !important;
}
page-item bar-rating .br-unit,
page-review-list bar-rating .br-unit {
  width: 16px !important;
  height: 16px !important;
}

page-review-add bar-rating .br-stars {
  margin: 8px 0 !important;
}
page-review-add bar-rating .br-units {
  justify-content: space-between;
}
page-review-add bar-rating .br-unit {
  width: 48px !important;
  height: 48px !important;
}

.ios ion-toolbar {
  --min-height: 60px;
}
.ios .tabs-bottom {
  transform: translateY(-50px) !important;
}

.md .tabs-bottom {
  transform: translateY(-56px) !important;
}

ion-toolbar {
  padding: 0 2px !important;
}

ion-toolbar .toolbar-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.notifications-badge {
  margin-left: -14px;
}

.brand-img {
  background: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 16px;
  background-position: center;
  height: 180px;
  margin: 10px 5px;
  position: relative;
}

.category-item {
  height: 100%;
  display: flex;
  justify-content: center;
  color: white;
  border-radius: 12px;
}

.category-item p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
}

.category-style {
  background-color: var(--ion-color-white);
  background-size: cover;
  background-position: center center;
  border-radius: 12px;
  width: 100%;
  max-width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}

.category-story img {
  width: 70px;
  height: 70px;
}

.category-story p {
  color: #191919;
  font-size: 10px !important;
}

@media (min-width: 992px) {
  .category-story img {
    width: 170px;
    height: 170px;
  }

  .category-story p {
    color: #191919;
    font-size: 18px !important;
  }
}
.item-info {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #234294;
  color: white;
  border-top: 3px solid #d6d6d6;
}

.featured {
  border-top: 3px solid #ffbb00ed;
  color: white;
}

.item-info ion-icon {
  width: 10px;
  color: #ecb211;
}

.item-price {
  font-size: 14px;
  font-weight: bold;
  color: #67748d;
  margin: 3px;
}

.item-price-item {
  color: #e0e0e0;
}

ion-item ion-thumbnail {
  width: 100px;
  height: 100px;
}

.prod-item .prod-item-inner {
  padding: 1px;
}

.prod-item .prod-name {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}

.prod-item .cat-name {
  font-size: 14px;
  font-weight: 100;
  color: rgba(119, 119, 119, 0.75) !important;
}

.prod-item .prod-price {
  margin: 2px 0;
}

.prod-item .rate ion-icon {
  font-size: 10px;
  color: #f39a00;
  margin-right: 2px;
}

.LoginModelPage-css .modal-wrapper {
  height: 80%;
  top: 20%;
  border-radius: 40px 40px 0 0;
  position: absolute;
  display: block;
}

.RegistrationModelPage-css .modal-wrapper {
  height: 90%;
  top: 10%;
  border-radius: 40px 40px 0 0;
  position: absolute;
  display: block;
}

.photModel-css .modal-wrapper {
  background-color: rgba(0, 0, 0, 0.801);
  position: absolute;
  display: block;
}

.photModel-css .img-block {
  height: 100%;
  width: 100%;
  background-color: #000;
}

h3,
h4,
h5,
h6 {
  margin-top: 10px !important;
}

:host ::ng-deep ion-toolbar {
  padding-top: 30px !important;
}

:host ::ng-deep ion-grid[_ngcontent-aor-c233] {
  --background: none !important;
  background-color: none !important;
  background: none !important;
}

.slider-slides {
  height: 100px;
}

.dark-layer {
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  padding: 10px;
}

.dark-layer p {
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 12px;
}

.dark-layer-2 {
  width: 100%;
  height: 34%;
  background-color: rgba(2, 2, 2, 0.64);
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
}

.dark-layer-2 p {
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 12px;
}

.bottomRow {
  margin-top: 0px;
}

.show-all-link {
  color: #6a0480;
  font-size: 18px;
  margin-bottom: 0;
}

.searchbar-input.sc-ion-searchbar-md {
  text-align: right;
}

ion-col p {
  margin: 3px 0;
  font-size: 10px;
}

.swiper-slide {
  border-radius: 10px;
  overflow: hidden;
}

.slider-block .titel-bg {
  background: linear-gradient(180deg, #73008c 0%, rgba(37, 0, 37, 0.0004376751) 0%, #6a0081 100%);
  height: 70%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.slider-block .slider-titel {
  position: absolute;
  bottom: 0;
  left: 16px;
  text-align: left;
  color: white;
}

.card-item {
  background-color: white;
  margin: 0;
}

.card-item .titel {
  margin: 8px 0 0px 0;
  color: #794684;
  font-size: 14px;
  font-weight: bold;
}

.card-item .author {
  margin: 5px 0;
  padding: 4px 8px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  background: #ac08d0;
  background: linear-gradient(90deg, #ac08d0 0%, #780979 0%, #9a00c8 100%);
}

.card-item .description {
  margin: 5px 0;
  color: #2c2c2c;
  font-size: 12px;
}

.card-item img {
  display: flex;
  width: fit-content;
  height: initial;
  border-radius: 15px;
  max-height: 90px;
  margin-bottom: 5px;
}

.card-item h4 {
  margin: 5px 0;
  color: #3a3a3a;
}

.card-item p {
  margin: 5px 0;
  color: #794684;
}

ion-item {
  background-color: #784584;
}

ion-searchbar {
  padding: 0 8px !important;
}

ion-slides {
  --bullet-background: var(--ion-color-light);
  --bullet-background-active: var(--ion-color-primary);
}
ion-slides .slide-img {
  background-color: var(--ion-color-white);
  background-size: cover;
  background-position: center center;
  border-radius: 12px;
  width: 100%;
  max-width: 100%;
  height: 140px;
}

ion-row.ion-nowrap {
  overflow: hidden;
}

@media (min-width: 992px) {
  .circle {
    width: 100px;
    height: 100px;
  }

  ion-slides .slide-img {
    height: 270px;
  }

  .brand-img {
    height: 300px;
  }
}
@media (min-width: 768px) {
  .brand-img {
    margin: 8px;
    height: 250px;
  }
}
.ion-padding {
  padding: 10px 7px;
}

.backBtn {
  position: absolute;
  left: 3vw;
  top: 13vh;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  border: none;
  background: #ffffff;
  z-index: 1;
}

.nextBtn {
  position: absolute;
  right: 3vw;
  top: 13vh;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  border: none;
  background: #ffffff;
  z-index: 1;
}

.shop-item {
  padding: 3px;
  background-color: white;
  margin: 10px 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 11px 0px #e3e3e369;
}

.shop-item .info p {
  font-size: 14px;
}

.shop-item img {
  height: 140px;
  margin-bottom: 5px;
  width: 80px !important;
  height: 80px !important;
}

.shop-item .add {
  display: flex;
  justify-content: space-around;
}

.shop-item .counter {
  height: 35px;
  text-align: center;
  margin: 1px 30px;
}

.shop-item span {
  margin-top: 7px;
  font-size: 18px;
}

.custom-tab {
  height: 60px !important;
  width: 100% !important;
  background-color: #2a4897;
  color: white;
}

:host ::ng-deep ion-grid[_ngcontent-aor-c233] {
  --background: none !important;
  background-color: none !important;
  background: none !important;
}

:host ::ng-deep ion-chip {
  background-color: #fff;
  border: 1px solid #00a8ff;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tag-name {
  color: #234493;
  font-weight: bold;
  font-size: 15px;
  text-transform: capitalize;
}

:host ::ng-deep ion-content {
  --padding-top: env(safe-area-inset-top) !important;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

ion-content {
  --background: url("assets/imgs/bg.jpg") 0 0 repeat;
  padding: 30px;
}

.full-page {
  padding: 30px;
}

.cursor {
  cursor: pointer;
}

:host .ionicon {
  stroke: none !important;
}

.action-sheet-title {
  --color: #234493;
  font-size: 18px !important;
  line-height: 21px;
  font-weight: 800 !important;
  font-family: "Montserrat", sans-serif;
}

.delete-action-sheet .action-sheet-title {
  --color: #e52729;
}

.action-sheet-sub-title {
  color: #7c7d7e !important;
  font-size: 14px !important;
  line-height: 17px;
  font-weight: 400 !important;
  font-family: "Montserrat", sans-serif;
}

.action-sheet-button {
  float: left !important;
  width: 45% !important;
}

.share-location-btn {
  background-color: #234493 !important;
  color: #fff !important;
  border-radius: 40px !important;
  margin-top: 40px !important;
  margin-bottom: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 75% !important;
  white-space: nowrap !important;
  text-align: center !important;
  padding-left: unset !important;
  padding-right: unset !important;
  left: 12% !important;
}

.no-btn {
  background-color: inherit !important;
  color: #e52729 !important;
  margin-bottom: 20px;
  text-align: center !important;
  text-decoration: underline;
  width: 100% !important;
  margin-top: 0px !important;
  font-size: 17px !important;
}

.action-sheet-group button:first-of-type {
  background-color: #234493;
  color: #fff;
  border-radius: 40px;
  width: 150px;
  height: 56px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
}

.action-sheet-group button:nth-of-type(2) {
  background-color: #e52729;
  color: #fff;
  border-radius: 40px;
  width: 150px;
  height: 56px !important;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}

.mat-expansion-panel-body {
  padding: 0 24px;
  text-align: left !important;
  color: #2c2c2c;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #234493 !important;
  margin-top: -7px;
}

.mat-form-field {
  width: 100%;
  border-radius: 20px;
  background: #f2f2f2;
  text-align: center;
  color: #b6b7b7;
  font-family: "Montserrat", sans-serif;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #f2f2f2 !important;
}

.transparent-background .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.transparent-background .mat-form-field-underline {
  width: 0px;
}

.transparent-background .mat-form-field-wrapper {
  padding-bottom: 0px;
  text-align: center;
}

.searchbar-input {
  border-radius: 50px !important;
}

.searchbar-search-icon {
  padding-left: 89% !important;
  color: #234493 !important;
}

@media only screen and (min-width: 997px) {
  .searchbar-search-icon {
    padding-left: 99% !important;
  }

  .email-alert-popup {
    --max-width: 35%;
  }
}
.add-button {
  --border-radius: 50% !important;
}

ion-footer {
  --background: url("assets/imgs/bg.jpg") 0 0 repeat !important;
  padding: 20px;
}

.note-input .native-input {
  position: relative;
  top: -10vh;
  padding-left: 10px;
}

ion-content {
  font-family: "Montserrat", sans-serif;
}

ion-header {
  font-family: "Montserrat", sans-serif;
}

.submit-btn {
  width: 97% !important;
  height: 50px;
  border-radius: 40px !important;
  background: #bdc3c7;
  color: #707070;
  font-size: 20px;
  font-weight: 500;
}

.cancel-btn {
  width: 97% !important;
  height: 50px;
  border-radius: 40px !important;
  background: #e52729;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.continue-btn {
  width: 97% !important;
  height: 50px;
  border-radius: 40px !important;
  background: #234493;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.something-wrong-popup .action-sheet-button {
  float: none !important;
  width: 95% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: #234493 !important;
}
.something-wrong-popup .action-sheet-title {
  color: #e52729 !important;
}

.success-popup .action-sheet-button {
  float: none !important;
  width: 95% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: #234493 !important;
}

.alert-wrapper {
  width: 100% !important;
}

.alert-title {
  color: #234493 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.email-inp-popup {
  width: 100% !important;
  height: 60px !important;
  border: 0 !important;
  background-color: #0000001a !important;
  border-radius: 40px !important;
  font-size: 18px !important;
  padding-left: 15px !important;
  color: #b6b7b7 !important;
  text-align: center;
}

.email-btn-popup {
  background: #234493 !important;
  border-radius: 50px !important;
  margin: 16px !important;
  height: 50px !important;
  color: white !important;
}

.email-alert-popup {
  --max-width: 90%;
}

@media only screen and (min-width: 997px) {
  .email-alert-popup {
    --max-width: 35%;
  }
}
.verification-dialog .mat-dialog-container {
  overflow-y: hidden !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.add-button {
  width: 35px;
  height: 35px;
}

.add-icon {
  font-size: 29px !important;
}

.shop-item span {
  white-space: nowrap;
}

.none-display {
  display: none;
}