ion-header {
  height: 96px;
  background: #234493;
  color: #fff;
}

ion-title{
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 30px;
}

ion-toolbar{
    --border-color: #234493;
}