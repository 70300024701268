

ion-col {
  padding-left: 34px;
  padding-right: 34px;
}

.main-logo {
  margin-top: 45px;
  img {
    width: 168px;
    height: 52px;
  }
}

.login-text {
  margin-bottom: 16px;
  color: #4a4b4d;
  font-size: 20px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

.login-input {
  background: #f2f2f2;
  height: 56px;
  border-radius: 50px;
  -–border-radius: 50px;
}

.login-item {
  background: transparent;
  --background: transparent;
}

.login-btn {
  background: #234493;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.forget-link {
  margin-top: 11px;
  color: #234493;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

// or
.or {
  margin-top: 13px;
  color: #7c7d7e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  height: 1px;
  border-top: 1px solid #7c7d7e;
  text-align: center;
  position: relative;

  span {
    position: relative;
    top: -0.7em;
    background: white;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.login-with {
  margin-top: 10px;
  color: #7c7d7e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.facebook-btn {
  height: 56px;
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  background: #4267b2;
  cursor: pointer;
  span {
    margin: 0 auto;
    background: url("../imgs/facebook-icon.png") no-repeat 0 50%;
    padding-left: 30px;
  }
}

.google-btn {
  background: #e52729;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  span {
    margin: 0 auto;
    background: url("../imgs/google-plus.png") no-repeat 0 50%;
    padding-left: 30px;
  }
}

.guest-btn {
  background: #234493;
  height: 56px;
  margin-top: 20px;
  width: 100%;
  border-radius: 50px;
  padding-top: 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  span {
    margin: 0 auto;
    background: url("../imgs/guest.png") no-repeat 0 40%;
    padding-left: 30px;
    height: 24px;
    display: table;
  }
}

.reg-div {
  color: #7c7d7e;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-top: 21px;
  margin-bottom: 40px;
  cursor: pointer;
  span {
    font-weight: 800;
    color: #234493;
  }
}

.auth-title {
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #4a4b4d;
  margin-top: 45px;
  font-size: 20px;
}

.auth-hint {
  line-height: 21px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #4a4b4d;
  margin-top: 45px;
  font-size: 14px;
  margin-top: 21px;
}

.otp-input {
  background: #f2f2f2;
  height: 56px;
  width: 56px;
  border-radius: 20px;
}

.sc-ion-input-ios-h {
  --padding-top: 0;
  --padding-end: 10;
  --padding-bottom: 10;
  --padding-start: 0;
}

.login-page {
  max-width: 500px;
  margin: 0 auto;
}

.btn-disabled {
  color: #4A4B4D;
  background: #7C7D7E;
}
